import { render, staticRenderFns } from "./WebPage.vue?vue&type=template&id=221b3790&scoped=true&"
import script from "./WebPage.vue?vue&type=script&lang=js&"
export * from "./WebPage.vue?vue&type=script&lang=js&"
import style0 from "./WebPage.vue?vue&type=style&index=0&id=221b3790&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "221b3790",
  null
  
)

export default component.exports