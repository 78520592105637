<template>
  <v-expansion-panels
    variant="accordion"
    :value="groupData.open_by_default ? 0 : undefined"
  >
    <v-expansion-panel>
      <v-expansion-panel-header>{{
        groupData.panel_title
      }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container fluid class="pa-0" data-testid="hotspot-group">
          <!--<span class="text-caption">{{ groupData.title }}</span>-->
          <v-radio-group
            v-show="groupData.selected_groups.group_item_type == 'radioBtn'"
            v-model="radiogroupValue"
            color="primary"
            mandatory
            class="mt-0"
          >
            <v-list-item
              v-for="(item, i) in groupData.selected_groups.layers"
              :key="i"
              color="primary"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <v-radio :label="item.title" :value="item.id" :data-testid="'hotspot-group-radio'+i"> </v-radio>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-radio-group>

          <v-list
            v-show="groupData.selected_groups.group_item_type == 'checkBox'"
          >
            <v-list-item-group
              class="mt-0"
              color="primary"
              v-for="(item, i) in groupData.selected_groups.layers"
              :key="`layer-${i}`"
            >
              <!-- Group with Checkbox as Header and Activator -->
              <v-list-group no-action :key="`group-${i}`">
                <template v-slot:activator>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title :data-testid="'hotspot-group-checkbox-title' + i"
                        v-text="item.title"
                      ></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                        color="secondary"
                        :input-value="checkboxGroupValue.includes(item.id)"
                        @change="handleCheckboxChange(item.id, $event)"
                        @click.stop
                        :data-testid="'hotspot-group-checkbox'+i"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </template>

                <!-- Nested List Items -->
                <v-list-item
                  v-for="(item2, i2) in item.locations"
                  :key="`item-${i}-${i2}`"
                  @click="listItemClick(item2)"
                  v-model="activeListItemId"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item2.title" :data-testid="'hotspot-group-option' + i2"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list-item-group>
          </v-list>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import StreamfieldBlock from "../blocks/streamfieldBlock.vue";

export default {
  props: ["groupData"],
  components: {
    StreamfieldBlock,
  },
  data: () => ({
    radiogroupValue: 0,
    checkboxGroupValue: [],
    checked: false,
    selectedItem: 0,
    showStream: false,
    activeCheckboxes: [],
    activelocation: null,
    activeListItemId: null,
  }),
  mounted() {
    console.log(this.groupData, "TRACK grouped data");
  },
  beforeCreate: function () {
    this.$options.components.StreamfieldBlock =
      require("../blocks/streamfieldBlock.vue").default;
  },
  methods: {
    updateStore: function (HotspotGroupID) {
      console.log('[SELECT GROUP UPDATE STORE]')
      const activeLayerGroup = [this.radiogroupValue];
      this.$store.commit("wagtail/setVisibleHotspotGroups", activeLayerGroup);
    },
    updateStoreCheckbox: function (HotspotGroupIDs) {
      console.log('[SELECT UPDATE STORE CHECK]')
      this.$store.commit(
        "wagtail/setVisibleHotspotGroups",
        this.activeCheckboxes
      );
    },
    checkboxClick(item) {
      // Logic to handle checkbox click
    },
    listItemClick(item) {
      const center = [Number(item.lng), Number(item.lat)];
      /* FIX ZOOM to use new zoom field not magic number*/
      let zoom = Number(0)
      if (item.zoom != null) {
        zoom = Number(item.zoom);
      } else {
        zoom = Number(18);
      }
      console.log('SCROLL', zoom, item.zoom )
      const payload = {
        zoomLevel: zoom,
        location: {
          lng: item.lng,
          lat: item.lat,
        },
        tilt_offset: 0,
        heading: 0,
      };

      this.activeListItemId = item.id;

      if (item.right_sidebar_content.length > 0) {
        console.log(item, "item data of clicked");
        this.$store.commit("map/setGroupLocation", item);
        this.$store.commit("map/setRightSidebarZoomLevel", item.zoom);
        this.$store.commit("map/setRenderLocation", null);
        this.$store.commit("map/setPrecinct", null);
      }
      this.$store.commit("map/setHotspotGroupSelectedLocation", payload);

      // Logic to handle list item click
    },
    handleCheckboxChange(itemId, isChecked) {
      if (isChecked && !this.checkboxGroupValue.includes(itemId)) {
        this.checkboxGroupValue.push(itemId);
        console.log("TRACK Updating", itemId, "is checked");
      } else if (!isChecked && this.checkboxGroupValue.includes(itemId)) {
        this.checkboxGroupValue = this.checkboxGroupValue.filter(
          (id) => id !== itemId
        );
        console.log("TRACK Updating", itemId, "is not checked");
      }
    },
  },
  created() {
    let self = this;

    this.checkboxGroupValue = this.groupData.selected_groups.layers
      .filter((layer) => layer.visible_by_default)
      .map((layer) => layer.id);

    this.showStream = true;
    //Ensure the first filter is applied at load
    //this.updateStore(this.radiogroupValue);
    this.updateStoreCheckbox(this.checkboxGroupValue);
  },
  computed: {
    hotspotGroupSelectedLocation() {
      return this.$store.state.map.hotspotGroupSelectedLocation;
    },
    layerOptions() {
      return this.$store.state.wagtail.layerOptions;
    },
    // tickLabels: function() {
    //   let labels = []
    //   this.sliderData.forEach( function(item) {
    //     labels.push(item.step)
    //   })
    //   return labels
    // }
    //Edit Here
    // isStreamfieldAvailable(){
    //  this.groupData.layers.forEach(function (item, i) {
    //   if (item.additional_detail == undefined || null) return true;
    //   return false
    // });
    // }
  },
  watch: {
    hotspotGroupSelectedLocation: function (value) {
        console.log('[SELECT GROUP CHANGE SELECT]', value)
      if (value == null) {
        this.activeListItemId = null;
      }
    },
    radiogroupValue: {
      handler(newVal) {
        console.log('[SELECT GROUP RGV]', newVal)
        //this.updateStore(newVal);
      },
      deep: true,
    },
    data: function (newVal) {
      console.log('[SELECT GROUP datastore]', newVal)      
    },
    checkboxGroupValue: {
      handler(newVal) {
        console.log('[SELECT GROUP CHECKGROUPVALUE]', newVal)   
        this.activeCheckboxes = [...newVal]; // Update the array with the current active checkboxes
        this.updateStoreCheckbox(this.activeCheckboxes);
      },
      deep: true, // Use deep watcher to detect changes inside the array
    },
  },
  beforeDestroy() {
    let payload = { key: this._uid, data: null };
    this.$store.commit("wagtail/setLayerOptions", payload);
  },
};
</script>

<style scoped>
.radio-bg {
  background-color: rgb(229, 229, 229);
}
.subheader-checkbox {
  /* Adjust these styles as needed */
  display: inline-block;
  vertical-align: middle;
}
</style>
