var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.debug)?_c('div',{ref:"rect",style:({
      width: String(_vm.width) + 'px',
      height: String(_vm.height) + 'px',
    }),on:{"mouseenter":function($event){_vm.showBubble = true}}},[_c('v-img',{staticClass:"hotspot-bg hotspot-btn",style:({
        width: String(_vm.width) + 'px',
        height: String(_vm.height) + 'px',
        opacity: 0.5,
      }),attrs:{"src":"/grid.png","tab-index":"-1","alt":"","id":"hotspot-img"}})],1):_vm._e(),(!_vm.debug)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.validIcon && !_vm.invisible),expression:"validIcon && !invisible"}],ref:"icon",staticClass:"icon-div",attrs:{"data-testid":"hotspot-icon-container"}},[_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(true || (_vm.showTooltip && !_vm.debug) || (_vm.showBubble && !_vm.debug)),expression:"true || (showTooltip && !debug) || (showBubble && !debug)"}],staticClass:"hotspot-hint fluid pa-0",attrs:{"fill-height":""}},[_c('v-row',{staticClass:"text-center pa-0 ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-scale-transition',{attrs:{"origin":"center center"}},[_c('ConcentricCircles',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showTooltip && !_vm.isCentered && !_vm.invisible),expression:"!showTooltip && !isCentered && !invisible"}],staticClass:"hotspot-circle"})],1),_c('v-scale-transition',{attrs:{"origin":"center center"}},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(
              (!_vm.showTooltip && !_vm.isCentered && !_vm.invisible && !_vm.showBubble) ||
              _vm.title === ''
            ),expression:"\n              (!showTooltip && !isCentered && !invisible && !showBubble) ||\n              title === ''\n            "}],class:!_vm.showTooltip && !_vm.showBubble
                ? 'pa-0 hotspot-title hotspot-icon'
                : 'pa-1 hotspot-title hotspot-icon',attrs:{"data-testid":"hotspot-icon","dark":"","color":_vm.hotspotColor,"rounded":"pill"},on:{"mouseenter":function($event){_vm.showBubble = true}}},[_c('v-icon',{staticClass:"pa-3",attrs:{"color":_vm.hotspotTextColor}},[_vm._v(_vm._s(_vm.icon))])],1)],1),(_vm.title !== '')?_c('v-scale-transition',{attrs:{"origin":"center center"}},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.invisible ? false : _vm.showTooltip || _vm.isCentered),expression:"invisible ? false : showTooltip || isCentered"}],class:_vm.showTooltip
                ? 'pa-1 hotspot-title hotspot-icon'
                : 'pa-0 hotspot-title hotspot-icon',style:(_vm.cardStyles),attrs:{"data-testid":"hotspot-expanded-label","dark":"","color":_vm.hotspotColor,"rounded":"pill"},on:{"mouseenter":function($event){_vm.showBubble = true}}},[_c('v-card-subtitle',{staticClass:"pa-2 ml-2 mr-2",class:{ 'white--text': !_vm.hotspotTextColor }},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":_vm.hotspotTextColor}},[_vm._v(_vm._s(_vm.icon))]),_c('span',{style:({ color: _vm.hotspotTextColor })},[_vm._v(_vm._s(_vm.title))])],1)],1)],1):_vm._e(),(_vm.text !== null && _vm.text !== '')?_c('v-scale-transition',{attrs:{"origin":"center center","hide-on-leave":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.invisible ? false : _vm.showBubble || _vm.isCentered),expression:"invisible ? false : showBubble || isCentered"}],staticClass:"bubble",style:(`bottom: ${_vm.hotspotSize.height}px; width: ${_vm.bubble_width}px;`)},[_c('p',[_vm._v(" "+_vm._s(_vm.text)+" ")])])]):_vm._e()],1)],1)],1):_vm._e(),(!_vm.debug)?_c('v-scale-transition',{attrs:{"origin":"center center"}},[_c('div',{ref:"rect",staticClass:"hotspot-bg hotspot-btn",style:({
        width: _vm.hotspotSizeString.width,
        height: _vm.hotspotSizeString.height,
      }),attrs:{"data-testid":'rectHotspot' + _vm.index,"id":"rectHotspot","alt":""},on:{"mouseenter":function($event){(_vm.showTooltip = true), (_vm.showBubble = true)},"mouseleave":function($event){(_vm.showTooltip = false), (_vm.showBubble = false)},"mousemove":function($event){(_vm.showTooltip = true), (_vm.showBubble = true)}}},[_c('div',[_c('v-btn',{style:({
            width: _vm.hotspotSizeString.width,
            height: _vm.hotspotSizeString.height,
          }),attrs:{"text":"","dark":"","aria-label":_vm.ariaLabel,"disabled":!_vm.clickable,"id":"hotspot-img","data-testid":'hotspotBtn' + _vm.index},on:{"click":_vm.clicked,"mousedown":_vm.mouseDown,"mousemove":_vm.mouseMove,"focus":_vm.onFocus}},[_c('div',{class:_vm.transparent ? 'img-no-background' : 'img-background',attrs:{"tabindex":"-1"}},[(_vm.hasThumbnail)?_c('v-img',{style:({
                width: _vm.hotspotSizeString.width,
                height: _vm.hotspotSizeString.height,
              }),attrs:{"src":_vm.thumbnail.file,"contain":"","tabindex":"-1","alt":""}}):_vm._e()],1),_vm._t("default")],2),_c('v-fade-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTooltip && !_vm.debug && _vm.clickable && _vm.showBubble),expression:"showTooltip && !debug && clickable && showBubble"}],staticClass:"hotspot-overlay"},[_c('v-container',{staticClass:"background",attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col')],1)],1)],1)])],1)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }