var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageHeaderCard',{staticClass:"map-header",attrs:{"transparent":false}}),_c('div',{staticClass:"map-body"},[_c('Sidebar',{staticClass:"map-sidebar",attrs:{"pageData":_vm.pageData}}),_c('Map',{class:'map',attrs:{"tabindex":"-1","pageData":_vm.pageData,"refs":"map","data-testid":"map"},on:{"mapMoved":function($event){_vm.accessibilityModalFlag = true;
        _vm.accessibilityModalFlag2 = true;}}}),_c('v-slide-x-reverse-transition',[(_vm.precinct || _vm.groupLocation)?_c('RightSidebar',{class:'map-sidebar-right',attrs:{"pageData":_vm.precinct,"groupData":_vm.groupLocation}}):_vm._e()],1)],1),_c('v-dialog',{directives:[{name:"show",rawName:"v-show",value:(_vm.accessibilityModalFlag2),expression:"accessibilityModalFlag2"}],ref:"accessibilityLightbox",attrs:{"content-class":"accessibility-lightbox","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;(_vm.accessibilityModalFlag2 = false),
        (_vm.accessibilityModalFlag = false),
        _vm.closeAccessibilityLightbox()}},scopedSlots:_vm._u([{key:"default",fn:function(dialog){return [_c('v-card',{attrs:{"data-testid":"mappage-accessibility-modal"}},[_c('FocusLoop',{attrs:{"is-visible":_vm.activeTrap}},[_c('v-card-title',{staticClass:"text-h5 accessibility-header lighten-2"},[_vm._v(" Accessibility Mode ")]),_c('v-tabs',{attrs:{"grow":"","show-arrows":""}},[_c('v-tab',{staticClass:"accessibility-tab",attrs:{"data-testid":"accessibility-sidebar-tab"}},[_vm._v(" Sidebar Content ")]),_c('v-tab-item',[(_vm.hasLandingStreamfield)?_c('v-list-item',{key:"1",staticClass:"accessibility-btn",attrs:{"link":""},on:{"click":function($event){return _vm.$emit('showLanding', 'read-welcome');}}},[_vm._v("Read Welcome Message")]):_vm._e(),_vm._l((_vm.pageData.children),function(item,i){return _c('v-list-item-group',{key:_vm.pageData.id + 'page_' + i,attrs:{"link":""},on:{"click":function($event){_vm.markerClicked(item),dialog.value = false;}}},_vm._l((_vm.sidebarContent),function(item,i){return _c('v-list-item',{key:_vm.pageData.id + 'page_' + i,staticClass:"accessibility-btn",attrs:{"data-testid":'accessibility-sidebar-item' + i,"link":""},on:{"click":function($event){_vm.markerClicked(item);dialog.value = false;}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)}),_c('v-list-item',{staticClass:"accessibility-btn",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.markerClicked()},"click":function($event){(_vm.sidebarLightbox = true), (dialog.value = false)}}},[_vm._v("Sidebar Overview ")]),(_vm.pageData.next_map !== null)?_c('v-list-item',{staticClass:"accessibility-btn",on:{"click":function($event){return _vm.gotoPage(_vm.pageData.next_map.html_url)}}},[_vm._v(" Next - "+_vm._s(_vm.pageData.next_map.title)+" ")]):_vm._e(),(
                  _vm.pageData.root_map !== null &&
                  _vm.pageData.root_map.id !== _vm.pageData.id
                )?_c('v-list-item',{staticClass:"accessibility-btn",on:{"click":function($event){return _vm.gotoPage(_vm.pageData.root_map.html_url)}}},[_vm._v(" Home - "+_vm._s(_vm.pageData.root_map.title)+" ")]):_vm._e()],2),_c('v-tab',{staticClass:"accessibility-tab",attrs:{"data-testid":"accessibility-map-tab"}},[_vm._v(" Map Content ")]),_c('v-tab-item',[_vm._l((_vm.mapContent),function(item,i){return _c('v-list-item',{key:_vm.pageData.id + 'page_' + i,staticClass:"accessibility-btn",attrs:{"link":"","data-testid":'accessibility-map-item' + i},on:{"click":function($event){_vm.markerClicked(item), (dialog.value = false)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),_c('v-list-item',{staticClass:"accessibility-btn",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.markerClicked()},"click":function($event){(_vm.mapLightbox = true), (dialog.value = false)}}},[_vm._v("Map Overview")]),(_vm.pageData.next_map !== null)?_c('v-list-item',{staticClass:"accessibility-btn",on:{"click":function($event){return _vm.gotoPage(_vm.pageData.next_map.html_url)}}},[_vm._v(" Next - "+_vm._s(_vm.pageData.next_map.title)+" ")]):_vm._e(),(
                  _vm.pageData.root_map !== null &&
                  _vm.pageData.root_map.id !== _vm.pageData.id
                )?_c('v-list-item',{staticClass:"accessibility-btn",on:{"click":function($event){return _vm.gotoPage(_vm.pageData.root_map.html_url)}}},[_vm._v(" Home - "+_vm._s(_vm.pageData.root_map.title)+" ")]):_vm._e()],2),_c('v-tab',{attrs:{"data-testid":"accessibility-navigation-tab"}},[_vm._v("Site Navigation ")]),_c('v-tab-item',_vm._l((_vm.siteStructure),function(menuItem,i){return _c('v-list-item',{key:i,attrs:{"to":menuItem.pathname,"link":"","data-testid":'accessibility-navigation-item' + i}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v(_vm._s(menuItem.menu_icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(menuItem.title))])],1)}),1)],1),_c('v-divider'),_c('v-btn',{staticClass:"close-accessibility-btn",attrs:{"tabindex":"0","data-testid":"accessibility-close-btn"},on:{"click":function($event){_vm.closeAccessibilityLightbox(), (dialog.value = false)}}},[_vm._v("Close")])],1)],1)]}}]),model:{value:(_vm.accessibilityModalFlag),callback:function ($$v) {_vm.accessibilityModalFlag=$$v},expression:"accessibilityModalFlag"}}),_c('MapLightbox',{attrs:{"pageData":_vm.pageData,"showLightbox":_vm.showLightbox,"accessibilityMode":_vm.accessibilityMode,"mapLightbox":_vm.mapLightbox,"data-testid":"map-overview-lightbox"},on:{"closed":function($event){(_vm.mapLightbox = false), (_vm.accessibilityModalFlag = true)}}}),_c('LightboxGallery',{attrs:{"showLightbox":_vm.lightboxContent.length > 0 && _vm.lightboxReady,"content":_vm.lightboxContent,"data-testid":"accessibility-lightbox"},on:{"closed":function($event){return _vm.closeLightbox()}}}),(_vm.sidebarLightbox)?_c('LightboxBase',{ref:"lightbox",attrs:{"showLightbox":_vm.showLightbox},on:{"closed":function($event){(_vm.sidebarLightbox = false), (_vm.accessibilityModalFlag = true)}}},[_c('Sidebar',{class:'map-sidebar-accessibility-mode',attrs:{"pageData":_vm.pageData}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }