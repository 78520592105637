<template>
  <LightboxContentLayout
    :accessibilityText="data.video.accessibility_text"
    :ariaDescription="ariaDescription"
    :captionText="data.video.caption"
    :accessibilityTextFlag="accessibilityTextFlag"
  >
    <View360
      v-if="videoToggle ? false : !videoAccessibilityFile"
      ref="viewer"
      @render="checkVideoHasEnded($event)"
      @load="contentLoaded()"
      @ready="testFunc()"
      class="view360-container"
      :projection="projection"
      :initialYaw="data.heading"
      style="width: 100%; height: 100%"
    />

    <video
      v-else
      :aria-label="accessibilityLabel"
      id="lightboxvideo"
      class="lightbox-video"
      controls
      muted
      autoplay
      ref="videoRef"
      type="video/mp4"
      crossorigin="anonymous"
    >
      <source :src="videoAccessibility" type="video/mp4" />

    </video>
  </LightboxContentLayout>
</template>

<script>
import LightboxContentLayout from "./LightboxContentLayout.vue";
import { View360, EquirectProjection, ControlBar } from "@egjs/vue-view360";
import "@egjs/vue-view360/css/view360.min.css";

export default {
  name: "LightboxVideo360Card",
  data: () => ({
    dialog: true,
    isMobile: false,
    windowWidth: window.screen.width,
    isTablet: false,
    isLaptop: false,
    projection: null,
  }),
  props: [
    "data",
    "accessibilityTextFlag",
    "accessibilityVideoFlag",
    "accessibilityMode",
    "accessibility",
    "ariaDescription",
  ],
  components: { LightboxContentLayout, View360 },
  mounted() {
    console.log(this.data, "video light data content");
  },
  created() {
    if(this.data.video){
      if(this.data.video.video !== undefined){
        let src = "";
          // if (process.env.NODE_ENV === "development") {
          //   src =
          //     process.env.VUE_APP_BACKEND_URL.slice(0, -1) +
          //     this.data.video.video.file;
          // } else{
            src = this.data.video.video.file;
          // }
        this.projection = new EquirectProjection({
            src: src,
            video: true,
          });
      }else if(this.data.video !== undefined){
        let src = "";
        //  if (process.env.NODE_ENV === "development") {
        //     src =
        //       process.env.VUE_APP_BACKEND_URL.slice(0, -1) +
        //       this.data.video.file;
        //   } else{
        src = this.data.video.file;
          // }
        this.projection = new EquirectProjection({
        src: src,
        video: true,
      });
      }
    }

  },
  computed: {
    videoToggle() {
      return this.$store.state.map.videoToggle
    },
    video() {
      if (this.data && this.data.video.file) return this.data.video.file;
      return undefined;
    },
    videoAccessibilityFile () {
      return this.data.videoAccessibility &&
        this.data.videoAccessibility.video &&
        this.data.videoAccessibility.video.file
    },
    videoAccessibility() {
      if (this.videoToggle) {
        if (this.videoAccessibilityFile) {
          if (process.env.NODE_ENV === "development") {
            return (
              process.env.VUE_APP_BACKEND_URL.slice(0, -1) +
              this.videoAccessibilityFile
            );
          }
  
          return this.videoAccessibilityFile
        }
      }
      return this.data.video && this.data.video.video.file;
    },
    // videoAccessibility() {
    //   if (this.accessibilityMode && this.accessibilityVideoFlag)
    //     return this.data.video.accessible_video;
    //   return this.data.video.file;
    // },
    accessibilityLabel() {
      if (
        this.data.videoAccessibility &&
        this.data.videoAccessibility.video.file
      )
        return this.data.videoAccessibility.video.accessibility_text;
      return undefined;
    },
    hasCaption() {
      if (this.data && this.data.video.caption)
        if (this.data.video.caption !== undefined) return true;
      return false;
    },
    // accessibilityMode() {
    //   return this.$store.state.wagtail.accessibilityMode;
    // },
    hasAccessibleText() {
      if (this.data && this.data.video.accessibility_text) return true;
      return false;
    },
    // videoWidth() {
    //   if (this.accessibilityTextFlag) return 60 + '%'
    //   if (this.hasAccessibleText &&
    //     this.hasCaption &&
    //     this.accessibilityTextFlag == true) return 70 + "%";
    //   return 100 + '%'
    // }
  },
  watch: {
    accessibilityVideoFlag(newValue) {
      console.log(newValue, "value changed video");
      var videoElement = document.getElementById("lightboxvideo");

      videoElement.pause();
      videoElement.removeAttribute("src");
      videoElement.setAttribute("src", this.data.video.file); // empty source
      videoElement.load();
    },
  },
  methods: {
    textFlag() {
      console.log("triggered here from image");
      if (this.accessibilityTextFlag == false) {
        this.accessibilityTextFlag = true;
      } else {
        this.accessibilityTextFlag = false;
      }
    },
    testFunc() {
      console.log("fire command");
      this.$refs.viewer.addPlugins(new ControlBar());
    },
    checkVideoHasEnded() {
      const time = document.getElementsByClassName("view360-controls-time")[0];
      if (!time) return;

      const [currentTime, videoDuration] = time.innerHTML.split(" / ");

      if (currentTime === videoDuration) {
        this.$emit("ended");
      }
    },
    contentLoaded() {
      this.$emit("loaded");
    },
  },
};
</script>

<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
}

video {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.view360-canvas {
  width: 1000px;
  height: 1000px;
}

.full-screen {
  width: 100%;
  height: 100%;
}

.lightbox-video {
  width: 100%;
  height: 100%;
}
</style>
