<template>
  <v-row justify="center">
    <video class="video" controls disablepictureinpicture width="100%" :poster="thumbnail" crossorigin="anonymous">
      <source :src="url" type="video/mp4" />
        <track
    label="English"
    kind="subtitles"
    srclang="en"
    :src="subtitle"
    default />
    </video>
  </v-row>
</template>

<script>
export default {
  name: "VideoBlock",
  props: ['url', 'width', 'height', 'thumbnail', 'subtitle'],
  mounted(){
     var video = document.querySelector('.video'); // get the video element
        var tracks = video.textTracks; // one for each track element
        var track = tracks[0]; // corresponds to the first track element
        track.mode = 'hidden';
  }
};
</script>

<style scoped>
</style>