<template>
  <div>
    <v-expansion-panels v-model="panel" :value="0" focusable multiple>
      <v-expansion-panel v-for="(card, i) in data" :key="i" :data-testid="'accordian-item' + i">
        <v-expansion-panel-header class="text-subtitle-1">
          {{ card.title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          <ParagraphBlock :html="card.content"></ParagraphBlock>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import ParagraphBlock from "./paragraphBlock.vue";

export default {
  name: "AccordianBlock",
  props: ["data"],
  data: () => ({
    panel: []
  }),
  components: { ParagraphBlock },
  methods: {},
  mounted() {
    // this.items = this.data.length
        // let self = this;



    console.log("data from accordian" , this.data, this.panel)

  },  
  created() {
    let self = this;


    this.data.forEach(function (item, i) {
      if (item.openByDefault == true) self.panel.push(i);
    });

    // this.showStream = true;
    // //Ensure the first filter is applied at load
    // this.updateStore(this.groupValue); 
    // this.updateStoreCheckbox(this.checkboxGroupValue);

  },
};
</script>

<style scoped>
</style>